import {apiService} from "../utils/serviceApi";
import {CLEAR_ZENDESK_TICKET, GET_ALL_TICKET_SERIES, GET_ZENDESK_TICKET, SET_LOADER, TICKETS_GENERATED,} from "./types";

export const getAllTicketSeries = () => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  const filter = {
    take: 1000,
    sort: {
      path: "date",
      desc: true,
    },
  };
  apiService
    .get(
      `${process.env.REACT_APP_SERVICE_URL}/api/ticketSeries?filter=${encodeURI(
        JSON.stringify(filter)
      )}`
    )
    .then((res) => {
      dispatch({
        type: GET_ALL_TICKET_SERIES,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const generateNewTickets =
  (
    createSeriesDto,
    successNotification,
    errorNotification,
    infoNotification,
    language
  ) =>
  async (dispatch) => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    apiService
      .post(
        `${process.env.REACT_APP_SERVICE_URL}/api/ticketSeries/generateTicketPdf/${language}`,
        createSeriesDto
      )
      .then((res) => {
        dispatch({
          type: TICKETS_GENERATED,
          payload: res.data,
        });
        successNotification();
      })
      .catch((e) => {
        if (e.message === "Network Error") {
          infoNotification();
        } else {
          errorNotification();
        }
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
  };

export const getZendeskTicket = (zendeskId) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .get(
      `${process.env.REACT_APP_SERVICE_URL}/api/report/getZendeskTicket/${zendeskId}`
    )
    .then((res) => {
      dispatch({
        type: GET_ZENDESK_TICKET,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const clearZendeskTicket = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ZENDESK_TICKET,
  });
};
