import GetApp from "@material-ui/icons/GetApp";
import moment from "moment";
import React from "react";
import {ticketSeriesType} from "../globals";
import {cellStyle, filterStyle, headerStyle} from "./columnsGeneral";
import { ticketSeriesTypes } from "../globals";

export function TicketSeriesColumns(t) {

const getTicketSeriesLabel = (value) => {
  const ticketSeriesType = ticketSeriesTypes.find((type) => type.value === value);
  return ticketSeriesType ? t(ticketSeriesType.label) : null;
};

  return [
    {
      title: t("Columns.Date"),
      field: "date",
      cellStyle: cellStyle,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
      render: (row) => {
        return moment(row.date).format("DD.MM.YYYY");
      },
      customFilterAndSearch: (searchValue, row) => {
        if (
          moment(row.date).format("DD.MM.YYYY").toString().includes(searchValue)
        ) {
          return true;
        }
      },
    },
    {
      title: t("Columns.Serial"),
      field: "number",
      cellStyle: cellStyle,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Size"),
      field: "size",
      cellStyle: cellStyle,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.TicketSeriesType"),
      field: "ticketSeriesType",
      cellStyle: cellStyle,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
      render: (row) => getTicketSeriesLabel(row.ticketSeriesType),
    },
    {
      title: t("Addresses.country"),
      field: "countryCode",
      cellStyle: cellStyle,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Attachment"),
      headerStyle: {
        ...headerStyle,
        textAlign: "center",
      },
      filterComponent: () => null,
      cellStyle: {
        ...cellStyle,
        textAlign: "center",
      },
      render: (row) => {
        if (row.attachmentUrl) {
          return (
              <GetApp
                  style={{ cursor: "pointer" }}
                  id="ticket-attachment"
                  alt="ticket-attachment"
                  height={20}
                  onClick={() => {
                    window.open(row.attachmentUrl, "_blank");
                  }}
                  />
          );
        }
        return null;
      },
    },
  ];
}
