import {colors, ticketLegalStatus, ticketStatus} from "../globals";


export const switchLegalStatus = (t, param) => {
    switch (param) {
      case ticketLegalStatus.NONE:
        return (
          <div>
            <span>{t("Violations.LegalStatus.NONE")}</span>
          </div>
        );
      case ticketLegalStatus.REMINDED:
        return (
          <div>
            <span style={{ color: "orange" }}>
              {t("Violations.LegalStatus.REMINDED")}
            </span>
          </div>
        );
      case ticketLegalStatus.SUED:
        return (
          <div>
            <span style={{ color: "red" }}>
              {t("Violations.LegalStatus.SUED")}
            </span>
          </div>
        );
      case ticketLegalStatus.TERMINATED:
        return (
          <div>
            <span style={{ color: "green" }}>
              {t("Violations.LegalStatus.TERMINATED")}
            </span>
          </div>
        );
      case ticketLegalStatus.INVOICE_SENT:
        return (
          <div>
            <span style={{ color: "purple" }}>
              {t("Violations.LegalStatus.INVOICE_SENT")}
            </span>
          </div>
        );

      case ticketLegalStatus.OPERATION_DOC_SENT:
        return (
          <div>
            <span style={{ color: "blue" }}>
              {t("Violations.LegalStatus.OPERATION_DOC_SENT")}
            </span>
          </div>
        );
      case ticketLegalStatus.TO_SUE:
        return (
          <div>
            <span style={{ color: "black" }}>
              {t("Violations.LegalStatus.TO_SUE")}
            </span>
          </div>
        );
      case ticketLegalStatus.TO_BE_ENFORCED:
        return (
          <div>
            <span style={{ color: "black" }}>
              {t("Violations.LegalStatus.TO_BE_ENFORCED")}
            </span>
          </div>
        );
      case ticketLegalStatus.PENALTY_FEE_DEMANDED:
        return (
          <div>
            <span style={{ color: "black" }}>
              {t("Violations.LegalStatus.PENALTY_FEE_DEMANDED")}
            </span>
          </div>
        );


      default:
        return "";
    }
  };

export const switchStatus = (t, row) => {
  if (row?.status === null) {
    return null;
  }
  switch (row.status) {
    case ticketStatus.ISSUED:
      return (
        <div>
          <span style={{ color: "orange" }}>
            {t("Violations.Status.ISSUED")}
          </span>
        </div>
      );
    case ticketStatus.PAID:
      return (
        <div style={{ color: "green" }}>{t("Violations.Status.PAID")}</div>
      );
    case ticketStatus.STOPPED:
      return (
        <div style={{ color: "orange" }}>{t("Violations.Status.STOPPED")}</div>
      );
    case ticketStatus.OVERDUE:
      return (
        <div style={{ color: "red" }}>{t("Violations.Status.OVERDUE")}</div>
      );
    case ticketStatus.DOWNPAID:
      return (
        <div>
          <span
            style={row.reminderDeadline ? { color: "red" } : { color: "grey" }}
          >
            {t("Violations.Status.DOWNPAID")}
          </span>
        </div>
      );
    case ticketStatus.CHECK:
      return (
        <div>
          <span style={{ color: "black" }}>{t("Violations.Status.CHECK")}</span>
        </div>
      );
    case ticketStatus.DONE:
      return (
        <div>
          <span style={{ color: "gray" }}>
            {t("Violations.Status.COMPLITED")}
          </span>
        </div>
      );
    case ticketStatus.PENDING:
      return (
        <div>
          <span style={{ color: "blue" }}>
            {t("Violations.Status.PENDING")}
          </span>
        </div>
      );
    case ticketStatus.GRACE:
      return (
        <div style={{ color: "black" }}>{t("Violations.Status.GRACE")}</div>
      );
    default:
      return "";
  }
};

export const switchUserStatus = (t, row) => {
  switch (row.status) {
    case ticketStatus.ISSUED:
    case ticketStatus.OVERDUE:
    case ticketStatus.DOWNPAID:
    case ticketStatus.PENDING:
      return (
        <div>
          <span style={{ color: colors.blue }}>
            {t("Violations.UserStatus.OPEN")}
          </span>
        </div>
      );
    case ticketStatus.PAID:
      return (
        <div style={{ color: colors.green }}>{t("Violations.UserStatus.PAID")}</div>
      );
    case ticketStatus.STOPPED:
      return (
        <div style={{ color: colors.red }}>{t("Violations.UserStatus.CANCELLED")}</div>
      );
    case ticketStatus.DONE:
      return (
        <div>
          <span style={{ color: colors.gray }}>
            {t("Violations.UserStatus.CLOSED")}
          </span>
        </div>
      );
    default:
      return "";
  }
};